<template>
  <div class="ud-body">
    <a-card :bordered="false">
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 2 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 12 }, sm: { span: 24 } }"
      >
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane :key="1" tab="基本信息">
            <a-form-item label="商品名称:" name="goodsName">
              <a-input
                v-model:value="form.goodsName"
                placeholder="请输入商品名称"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="商品卖点:" name="sellingPoint">
              <a-input
                v-model:value="form.sellingPoint"
                placeholder="请输入商品卖点"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="商品分类:" name="categoryId">
              <a-select
                allow-clear
                placeholder="请选择商品分类"
                v-model:value="form.categoryId"
              >
                <a-select-option
                  v-for="item in sortList"
                  :key="item.categoryId"
                  :value="item.categoryId"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商品编码:" name="goodsCode">
              <a-input
                v-model:value="form.goodsCode"
                placeholder="请输入商品编码"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="状态:" name="goodsStatus">
              <a-select
                allow-clear
                placeholder="状态查询"
                v-model:value="form.goodsStatus"
              >
                <a-select-option
                  v-for="item in stateDict"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商品图片:" name="goodsPics">
              <a-upload
                v-model:file-list="fileList"
                list-type="picture-card"
                :remove="removeFile"
                :customRequest="doUpload"
                @preview="handlePreview"
              >
                <div v-if="fileList.length < 8">
                  <plus-outlined />
                  <div class="ant-upload-text">图片</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{
                  textAlign: 'center',
                  paddingTop: '40px',
                  width: '100%',
                  overflow: 'auto'
                }"
                @cancel="previewVisible = false"
              >
                <img alt="example" :src="previewImage" />
              </a-modal>
              <div>
                <a-radio-group
                  name="radioGroup"
                  v-model:value="radioValue"
                  :default-value="radioValue"
                  @change="radioChange"
                >
                  <a-radio
                    :style="radioStyle"
                    :value="index"
                    v-for="(item, index) in this.fileList.length"
                    :key="index"
                  ></a-radio>
                </a-radio-group>
              </div>
              <div style="color: #999999">
                注：主图片默认为第一张，单选可自主选择主图片，最多可上传8张！图片比例最好为750*750！
              </div>
            </a-form-item>
            <a-form-item
              label=" "
              :colon="false"
              :labelCol="{ span: 2, offset: 0 }"
              name="virtualViews"
            >
              <a-button type="primary" @click="save"> 提交 </a-button>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane :key="2" tab="规格/库存">
            <a-form-item label="规格类型:" name="goodsType">
              <a-radio-group
                v-model:value="goodsType"
                @change="goodsTypeChange"
              >
                <a-radio :value="0">单规格</a-radio>
                <a-radio :value="1">多规格</a-radio>
              </a-radio-group>
            </a-form-item>
            <div v-if="this.goodsType == 0">
              <a-form-item
                label="商品价格:"
                :labelCol="{ span: 2, offset: 0 }"
                :wrapper-col="{ md: { span: 6 } }"
                name="goodsPrice"
              >
                <a-input
                  v-model:value="form.goodsPrice"
                  placeholder="请输入商品价格"
                  allow-clear
                >
                  <template #suffix>元</template>
                </a-input>
                <div style="color: #999999">商品的实际购买金额，最低0.01</div>
              </a-form-item>
              <a-form-item
                label="商品数量:"
                :labelCol="{ span: 2, offset: 0 }"
                :wrapper-col="{ md: { span: 6 } }"
                name="goodsNumber"
              >
                <a-input
                  v-model:value="form.goodsNumber"
                  placeholder="请输入商品数量"
                  allow-clear
                >
                  <template #suffix>件</template>
                </a-input>
                <div style="color: #999999">
                  库存的实际库存数量，为0时用户无法下单
                </div>
              </a-form-item>
              <a-form-item
                label="商品重量:"
                :labelCol="{ span: 2, offset: 0 }"
                :wrapper-col="{ md: { span: 6 } }"
                name="goodsweight"
              >
                <a-input
                  v-model:value="form.goodsweight"
                  placeholder="请输入商品重量"
                  allow-clear
                >
                  <template #suffix>千克(Kg)</template>
                </a-input>
                <div style="color: #999999">商品的实际重量，用于计算运费</div>
              </a-form-item>
            </div>
            <div v-if="this.goodsType == 1">
              <a-form-item
                label="商品规格:"
                :labelCol="{ span: 2, offset: 0 }"
                :wrapper-col="{ md: { span: 16 } }"
                name="goodsweight"
              >
                <div style="color: #999999">
                  最多添加3个商品规格组，生成的SKU数量不能超过50个
                </div>
                <div
                  class="goodsSpecs"
                  v-for="(item, index) in goodsSpecs"
                  :key="index"
                >
                  <div class="goodsSpecsHeader">
                    <a-row type="flex" justify="space-between">
                      <a-col :span="6">
                        <a-input
                          v-model:value="item.title"
                          placeholder="请输入规格名称"
                          @focus="attrFocus(item.title)"
                          @blur="attrBlur(item.title, index)"
                        />
                      </a-col>
                      <a @click="deleteSpecsBtn(index)">删除规格组</a>
                    </a-row>
                  </div>
                  <div class="goodsSpecsValue">
                    <a-row :gutter="[16, 16]">
                      <a-col
                        :span="6"
                        v-for="(
                          specsValue, specsValueIndex
                        ) in item.specsValueList"
                        :key="specsValueIndex"
                      >
                        <div
                          style="position: relative"
                          @mouseenter.stop="mouseenter(specsValue, $event)"
                          @mouseleave.stop="mouseleave(specsValue, $event)"
                        >
                          <a-input
                            v-model:value="
                              item.specsValueList[specsValueIndex].title
                            "
                            placeholder="请输入规格值"
                            ref="attrValueInput"
                            @focus="
                              attrValueFocus(
                                item.specsValueList[specsValueIndex].title
                              )
                            "
                            @blur="
                              newAttrValueBlur(
                                item.dataIndex,
                                item.specsValueList[specsValueIndex].title,
                                index,
                                specsValueIndex
                              )
                            "
                          >
                          </a-input>
                          <div
                            class="iconStyle"
                            v-if="specsValue.closeIcon"
                            @click="
                              closeIconClick(
                                index,
                                specsValueIndex,
                                item.dataIndex,
                                item.specsValueList[specsValueIndex].title
                              )
                            "
                          >
                            <CloseCircleFilled
                              :style="{ fontSize: '16px', color: '#7d7d7d' }"
                            />
                          </div>
                        </div>
                      </a-col>
                      <a @click="addSpecsValueBtn(index)">新增规格值</a>
                    </a-row>
                  </div>
                </div>
                <div class="addSpecsBtn" v-if="goodsSpecs.length < 3">
                  <a-button @click="addSpecsBtn">
                    <PlusOutlined />添加规格组
                  </a-button>
                </div>
              </a-form-item>
              <a-form-item
                label="SKU列表:"
                :labelCol="{ span: 2, offset: 0 }"
                :wrapper-col="{ md: { span: 16 } }"
                name="sku"
                v-if="this.goodsSpecs != 0"
              >
                <a-form-item
                  label="批量设置:"
                  :labelCol="{ span: 2, offset: 0 }"
                  :wrapper-col="{ md: { span: 22 } }"
                  name="sku"
                >
                  <a-row :gutter="16">
                    <a-col :md="4" :sm="24" :xs="24">
                      <a-input-number
                        :min="0"
                        :precision="2"
                        v-model:value="goodsPriceValue"
                        placeholder="商品价格"
                      />
                    </a-col>
                    <a-col :md="4" :sm="24" :xs="24">
                      <a-input-number
                        :min="0"
                        :precision="2"
                        v-model:value="scribingPriceValue"
                        placeholder="划线价格"
                      />
                    </a-col>
                    <a-col :md="4" :sm="24" :xs="24">
                      <a-input-number
                        :min="0"
                        v-model:value="amountValue"
                        placeholder="库存数量"
                      />
                    </a-col>
                    <a-col :md="4" :sm="24" :xs="24">
                      <a-input-number
                        :min="0"
                        :precision="2"
                        v-model:value="goodsWeightValue"
                        placeholder="商品重量"
                      />
                    </a-col>
                    <a-col :md="4" :sm="24" :xs="24">
                      <a-input v-model:value="skuValue" placeholder="sku编码" />
                    </a-col>
                    <a-col :md="4" :sm="24" :xs="24">
                      <a-button @click="batchClick">立刻设置</a-button>
                    </a-col>
                  </a-row>
                  <!-- 笛卡尔表格 -->
                </a-form-item>
                <a-table
                  ref="table"
                  row-key="goodsId"
                  :dataSource="dataSource"
                  :columns="columns"
                  :align="center"
                  :pagination="false"
                  bordered
                >
                  <template #img="{ record }">
                    <a-upload
                      class="uploadStyle"
                      list-type="picture-card"
                      v-model:file-list="record.imgList"
                      @preview="skuHandlePreview"
                      :customRequest="
                        ({ file }) => uploadFile(record, file, 'goodsImage')
                      "
                      @change="fileListChange(record, $event)"
                      :remove="removeFile"
                    >
                      <div v-if="record.imgList.length === 0">
                        <a-popover trigger="hover" placement="top">
                          <template #content>
                            <div class="ant-upload-text">点击选择预览图</div>
                          </template>
                          <PlusOutlined
                            :style="{ fontSize: '36px', color: '#dbdada' }"
                          />
                        </a-popover>
                      </div>
                    </a-upload>
                    <a-modal
                      :visible="skuPreviewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="skuPreviewImage"
                      />
                    </a-modal>
                  </template>
                  <template #number="{ record, column }">
                    <a-input-number
                      :min="0"
                      v-model:value="record[column.dataIndex]"
                      placeholder=""
                    />
                  </template>
                  <template #price="{ record, column }">
                    <a-input-number
                      :min="0"
                      :precision="2"
                      v-model:value="record[column.dataIndex]"
                      placeholder=""
                    />
                  </template>
                  <template #input="{ record, column }">
                    <a-input
                      v-model:value="record[column.dataIndex]"
                      placeholder=""
                    />
                  </template>
                </a-table>
              </a-form-item>
            </div>
            <a-form-item
              label=" "
              :colon="false"
              :labelCol="{ span: 2, offset: 0 }"
              name="virtualViews"
            >
              <a-button type="primary" @click="save"> 提交 </a-button>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane :key="3" tab="商品详情" :forceRender="true">
            <a-form-item
              label="商品详情"
              :labelCol="{ span: 2, offset: 0 }"
              name="goodsContent"
            >
              <tinymce-editor
                v-model:value="form.goodsContent"
                :init="{ height: 525 }"
              />
            </a-form-item>
            <a-form-item
              label=" "
              :colon="false"
              :labelCol="{ span: 2, offset: 0 }"
              name="virtualViews"
            >
              <a-button type="primary" @click="save"> 提交 </a-button>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane :key="4" tab="更多设置">
            <a-form-item label="商品承诺:" name="promise">
              <a-select
                allow-clear
                placeholder="请选择商品承诺"
                v-model:value="form.categoryId"
              >
                <a-select-option
                  v-for="item in promiseList"
                  :key="item.categoryId"
                  :value="item.categoryId"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label=" "
              :colon="false"
              :labelCol="{ span: 2, offset: 0 }"
              name="virtualViews"
            >
              <a-button type="primary" @click="save"> 提交 </a-button>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import setting from '@/config/setting'
import TinymceEditor from '@/components/TinymceEditor'
import { Modal } from 'ant-design-vue'
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons-vue'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

/**
 * @param text 当前单元格的值
 * @param array 当前分页所有数据
 * @param columns 当前列的dataIndex
 * @returns {number} 待合并单元格数量
 */
var formatDataTemp = {} // 当前重复的值,支持多列
const mergeCells = (array, index, column, columns) => {
  let rowSpan = 0
  if (array.length === 1 || array.length === 0) {
    return 1
  }

  if (!columns) {
    const text = array[index][column]
    if (text !== formatDataTemp[column]) {
      formatDataTemp[column] = text
      array.forEach((item, index, arr) => {
        if (item[column] === formatDataTemp[column]) {
          rowSpan++
        }
      })
    }
    return rowSpan
  }

  const value = getText(array[index], columns)
  if (value !== formatDataTemp[column]) {
    formatDataTemp[column] = value
    array.forEach((item, index, arr) => {
      if (getText(item, columns) === formatDataTemp[column]) {
        rowSpan++
      }
    })
  }
  return rowSpan
}

const getText = (data, columns) => {
  const _columns = columns.split(',')
  let _value = ''
  for (var i = 0; i < _columns.length; i++) {
    _value += data[_columns[i]]
  }
  return _value
}

export default {
  name: 'goodsIndexEdit',
  components: {
    TinymceEditor,
    PlusOutlined,
    CloseCircleFilled
  },
  data() {
    return {
      activeKey: 1,
      goodsType: 0,
      value: '',
      form: {
        goodsName: '',
        goodsContent: ''
      },
      // 编辑弹窗表单验证规则
      rules: {
        goodsName: [
          {
            required: true,
            message: '请输入商品名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ],
        sellingPoint: [
          {
            required: true,
            message: '请输入商品买点',
            type: 'string',
            validateTrigger: 'blur'
          }
        ],
        goodsContent: [
          {
            required: true,
            message: '请输入商品详情',
            type: 'string',
            validateTrigger: ['blur', 'change']
          }
        ],
        promise: [
          {
            required: true,
            message: '请选择商品承诺',
            type: 'string',
            validateTrigger: 'blur'
          }
        ]
      },
      // 商品图片
      fileList: [],
      previewImage: '',
      previewVisible: false,
      // sku图片
      imgList: [],
      skuPreviewImage: '',
      skuPreviewVisible: false,
      uploadUrl: setting.uploadUrl,
      goodsPics: '',
      goodsImage: '',
      isUpdate: false,
      content: '',
      onshow: true,
      // 商品分类名称list
      sortList: [],
      // 商品承诺list
      promiseList: [],
      // 状态字典项
      stateDict: [],
      radioValue: 0,
      radioStyle: {
        width: '112px',
        textAlign: 'center',
        marginRight: '0px',
        paddingBottom: '10px'
      },
      specsId: 0,
      goodsSpecs: [],
      dataSource: [],
      columns: [],
      goodsPriceValue: '',
      scribingPriceValue: '',
      amountValue: '',
      goodsWeightValue: '',
      skuValue: '',
      goodsId: -1, // 新增dataSource的id
      specCounts: 0, // 数量
      // 规格组旧值
      oldTitle: '',
      // 规格值旧值
      oldSpecsValueTitle: '',
      // 规格值索引
      specIndex: '',
      // 表格头部
      tableHeaderList: [],
      valueNum: [],
      // 笛卡尔数组
      cartesianArr: [],
      // 每一列的插槽名 - 表格行内编辑用
      customRenderList: [
        'goodsPrice',
        'scribingPrice',
        'amount',
        'goodsWeight',
        'sku'
      ]
    }
  },
  created() {},
  watch: {
    data() {},
    $route(to, from) {
      //   if (to.name === '/jyyw/points/productEdit') {
      //     this.onshow = true
      //     this.content = this.form.goodsContent
      //     if (this.$route.query.goodsId) {
      //       this.queryArticle()
      //       this.isUpdate = true
      //     } else {
      //       this.form = {}
      //       this.content = ''
      //       this.isUpdate = false
      //     }
      //   } else {
      //     if (this.onshow) {
      //       this.onshow = false
      //       this.form.goodsContent = this.content
      //       this.content = ''
      //     }
      //   }
    }
  },
  mounted() {},
  methods: {
    // 输入规格组内容添加到sku列表上
    generateTableColumn() {
      this.columns = this.goodsSpecs.concat([
        {
          title: '预览图',
          dataIndex: 'goodsImage',
          width: '10%',
          align: 'center',
          slots: { customRender: 'img' }
        },
        {
          key: 'goodsPrice',
          title: '商品价格',
          dataIndex: 'goodsPrice',
          width: '15%',
          align: 'center',
          slots: { customRender: 'price' }
        },
        {
          key: 'scribingPrice',
          title: '划线价格',
          dataIndex: 'scribingPrice',
          width: '15%',
          align: 'center',
          slots: { customRender: 'price' }
        },
        {
          key: 'amount',
          title: '库存数量',
          dataIndex: 'amount',
          width: '15%',
          align: 'center',
          slots: { customRender: 'number' }
        },
        {
          key: 'goodsWeight',
          title: '商品重量(kg)',
          dataIndex: 'goodsWeight',
          width: '15%',
          align: 'center',
          slots: { customRender: 'price' }
        },
        {
          key: 'sku',
          title: 'SKU编码',
          dataIndex: 'sku',
          width: '15%',
          align: 'center',
          slots: { customRender: 'input' }
        }
      ])
      console.log('表格新头部', this.columns)
    },
    // 规格组获取焦点 得到旧的值 存一下
    attrFocus(oldVal) {
      this.oldTitle = oldVal
      console.log('焦点', oldVal)
    },
    // 规格组失去焦点
    attrBlur(newVal, index) {
      // 如果 '新值等于旧值' 或者 '空' 什么也不做
      if (newVal === this.oldTitle || newVal === '') return

      console.log('失去焦点attrBlur', newVal)

      this.goodsSpecs[index].title = newVal
    },
    // 规格值获得焦点时 得到旧的值 在输入框失去焦点的时候, 如果值没有变化, 则什么也不做
    attrValueFocus(oldVal) {
      console.log('规格值焦点', oldVal)
      this.oldSpecsValueTitle = oldVal
    },
    // 规格值失去焦点时, 操作表格数据 (新版本 可以实现无限个规格)
    newAttrValueBlur(currTitle, newVal, index, specsValueIndex) {
      console.log('规格值失去焦点currTitle', currTitle)
      console.log('规格值失去焦点newVal', newVal)
      console.log('规格值失去焦点index', index)
      console.log('规格值失去焦点specsValueIndex', specsValueIndex)
      // if (currTitle === '') return
      // if (newVal === this.oldSpecsValueTitle) return
      // 判断规格值是否重复
      this.valueNum = this.goodsSpecs[index].specsValueList.filter(
        (item) => item.title === newVal
      )
      if (this.valueNum.length > 1) {
        this.$message.error('规格值不能重复')
        this.goodsSpecs[index].specsValueList[specsValueIndex].title = ''
        return true
      }

      //  这里根据规格生成的笛卡尔积计算出table中需要改变的行索引 ( 包含新增和修改 )
      this.cartesianArr = this.generateBaseData(this.goodsSpecs)
      var changeIdxArr = [] // 需要被改变的行的索引
      for (var i in this.cartesianArr) {
        if ((this.cartesianArr[i][currTitle] || '') === newVal) {
          changeIdxArr.push(Number(i))
        }
        console.log('change_idx_arr', changeIdxArr)
      }
      // 新的表格应该有的长度与现有的表格长度比较, 区分新增还是修改
      var lengthArr = this.goodsSpecs.map((x) => x.specsValueList.length)
      var newTableLength = lengthArr.reduce((acc, currItem) => acc * currItem) // 新的表格数据长度 求乘积
      var oldTableLength = this.dataSource.length // 旧的表格数据长度
      this.specCounts = newTableLength
      // 如果是修改
      // if (newTableLength === oldTableLength) {
      //   this.dataSource.forEach((item, index) => {
      //     if (changeIdxArr.includes(index)) {
      //       this.dataSource[index][currTitle] = newVal
      //     }
      //   })
      //   this.formatData(this.dataSource)
      //   return true
      // }
      // 如果是新增
      if (newTableLength > oldTableLength) {
        // console.log('newTableLength', newTableLength)
        // console.log('oldTableLength', oldTableLength)
        // 得到当前属性的当前值和其他规格的 goodsSpecs, 构造新的表格数据
        var otherSkuArr = this.goodsSpecs.map((item) => {
          if (item.dataIndex !== currTitle) return item
          else {
            return {
              id: this.goodsSpecs[index].id,
              title: item.title,
              dataIndex: item.dataIndex,
              specsValueList: [
                {
                  id: 'SV' + this.goodsSpecs[index].specsValueList.length,
                  title: newVal,
                  closeIcion: false
                }
              ]
            }
          }
        })
        // 得到新增的表格数据
        var readyMap = this.generateBaseData(otherSkuArr)
        var newTableData = this.mergeTableData(readyMap)
        changeIdxArr.forEach((itemIdx, index) => {
          this.dataSource.splice(itemIdx, 0, newTableData[index])
        })
        this.formatData(this.dataSource)
        return
      }

      this.traverseSku()
      this.generateTableColumn()
      console.log('规格值失去焦点', currTitle)
    },

    formatData(data) {
      formatDataTemp = []
      for (let index = 0; index < data.length; index++) {
        // eslint-disable-next-line prefer-const
        let keys = []
        for (let j = 0; j < this.goodsSpecs.length; j++) {
          keys.push(this.goodsSpecs[j].dataIndex)
          data[index][this.goodsSpecs[j].dataIndex + 'RowSpan'] = mergeCells(
            data,
            index,
            this.goodsSpecs[j].dataIndex,
            keys.join(',')
          )
        }
      }
      return data
    },

    // 合并 goodsSpecs 与 '预览图', '商品价格', '划线价格' , '库存数量', '商品重量','SKU编码'返回整个表格数据数组
    mergeTableData(arrs) {
      const temp = arrs.map((item) => {
        this.goodsId++
        return {
          ...item,
          goodsId: 'sku' + this.goodsId,
          key: '',
          imgList: [], // 预览图
          goodsPrice: '', // 商品价格
          scribingPrice: '', // 划线价格
          amount: '', // 库存数量
          goodsWeight: '', // 库存数量
          sku: '' // SKU编码
          // customRender: renderContent
        }
      })

      return temp
    },
    // 遍历 `goodsSpecs` 生成表格数据
    traverseSku() {
      const readyMapSub = this.generateBaseData(this.goodsSpecs)
      this.dataSource = this.mergeTableData(readyMapSub)
      this.formatData(this.dataSource)
      console.log('dataSource', this.dataSource)
      this.specCounts = this.dataSource.length
    },
    // 重新实现笛卡尔积  入参是: this.goodsSpecs 传入的数组 '为空', '长度为1', '长度大于1' 三种情况 分别处理
    generateBaseData(arr) {
      if (arr.length === 0) return []
      if (arr.length === 1) {
        var [itemSpec] = arr
        return itemSpec.specsValueList.map((x) => {
          return {
            [itemSpec.dataIndex]: x.title
          }
        })
      }
      if (arr.length >= 1) {
        // 从左边开始减去数组中的数字
        return arr.reduce((accumulator, specItem) => {
          // console.log('accumulator', accumulator)
          // console.log('specItem', specItem)
          // console.log('accumulator2', accumulator.specsValueList)
          // accumulator判断之前的数据是  整合的规格数组还是单个规格项
          let accValueList = []
          accValueList = Array.isArray(accumulator.specsValueList)
            ? accumulator.specsValueList
            : accumulator
          // 获取当前项的valueList
          let itemValueList = []
          itemValueList = specItem.specsValueList
          var result = []
          for (var i in accValueList) {
            for (var j in itemValueList) {
              // 计算每种组合
              let tempData = {}
              if (!accValueList[i].title) {
                // accumulator不是Array的情况
                tempData = {
                  ...accValueList[i],
                  [specItem.dataIndex]: itemValueList[j].title
                }

                // 否则如果是单个规格项
              } else {
                tempData[accumulator.dataIndex] = accValueList[i].title
                tempData[specItem.dataIndex] = itemValueList[j].title
              }
              result.push(tempData)
            }
          }
          return result
        })
      }
    },
    goodsTypeChange(e) {
      this.goodsType = e.target.value
    },
    // 添加规格组
    addSpecsBtn() {
      this.goodsSpecs.push({
        id: '',
        title: '',
        dataIndex: 'C' + this.specsId,
        width: '10%',
        align: 'center',
        customRender: ({ text, record, column }) => {
          const obj = {
            children: text,
            props: {}
          }
          obj.props.rowSpan = record[column.dataIndex + 'RowSpan']
          return obj
        },
        specsValueList: [{ title: '', closeIcon: false }]
      })
      this.specsId++
      this.traverseSku()
      this.generateTableColumn()
    },
    // 删除规格组
    deleteSpecsBtn(index) {
      this.$confirm({
        title: '您确定要删除该规格组吗?',
        content: '删除后不可恢复',
        maskClosable: true,
        onOk: () => {
          this.goodsSpecs.splice(index, 1)
          this.traverseSku()
          this.generateTableColumn()
        }
      })
    },
    // 添加规格值
    addSpecsValueBtn(index) {
      if (this.dataSource.length > 50) {
        this.$message.error(
          '生成的sku列表数量不能大于50个，当前数量：' +
            this.dataSource.length +
            '个'
        )
      } else {
        this.goodsSpecs[index].specsValueList.map((item) => {
          this.specIndex = item.title
        })
        if (this.specIndex === '') {
          this.$message.error('您有规格值未填写，请填写完再添加')
        } else {
          this.goodsSpecs[index].specsValueList.push({
            id:
              'SV' +
              this.goodsSpecs[index].specsValueList.length +
              '&' +
              this.goodsSpecs[index].id,
            title: '',
            closeIcon: false
          })
        }
      }
      // 让新增的输入框获得焦点
      // this.$nextTick(() => {
      //   this.$refs.attrValueInput[this.$refs.attrValueInput.length - 1].focus()
      // })
    },
    // 删除规格值 item, index
    closeIconClick(index, specsValueIndex, attrName, attrVal) {
      this.$confirm({
        title: '您确定要删除该规格值吗?',
        content: '删除后不可恢复',
        maskClosable: true,
        onOk: () => {
          if (this.goodsSpecs[index].specsValueList.length === 1) {
            this.$message.error('规格值最少保留一个')
            return
          }
          this.goodsSpecs[index].specsValueList.splice(specsValueIndex, 1)
          // 删除table行
          let dataLength = this.dataSource.length
          for (let i = 0; i < dataLength; i++) {
            if (this.dataSource[i][attrName] === attrVal) {
              this.dataSource.splice(i, 1)
              i = i - 1
              dataLength = dataLength - 1
            }
          }
          this.generateTableColumn()
          this.formatData(this.dataSource)
        }
      })
    },
    // 鼠标移入事件
    mouseenter(item, e) {
      // console.log('移入', e)
      item.closeIcon = true
    },
    // 鼠标移出事件
    mouseleave(item, e) {
      // console.log('移出', e)
      item.closeIcon = false
    },
    /* 上传 */
    // doUpload(data) {
    //   const formData = new FormData()
    //   formData.append('file', data.file)
    //   const hide = this.$message.loading('上传中..', 0)
    //   this.$http
    //     .post('/file/upload', formData)
    //     .then((res) => {
    //       hide()
    //       if (res.data.code === 0) {
    //         // this.goodsPics = res.data.location
    //         this.fileList[this.fileList.length - 1] = {
    //           ...this.fileList[this.fileList.length - 1],
    //           status: 'done',
    //           url: res.data.location
    //         }
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    //   return false
    // },
    // removeFile(file) {
    //   this.$http
    //     .delete('/file/remove', {
    //       path: file.url
    //     })
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         if (this.fileList.length > 0) {
    //           const urls = this.fileList.map((item) => {
    //             return item.url
    //           })
    //           if (urls.length > 0) {
    //             this.form.goodsPics = urls.join(',')
    //           }
    //         } else {
    //           this.form.goodsPics = ''
    //         }
    //         this.$message.success(res.data.msg)
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //     .catch((err) => {
    //       this.$message.error(err)
    //     })
    // },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    save() {
      // this.saveProcess()
      this.$refs.form
        .validate()
        .then(() => {
          // this.$message.success('校验成功')
          // console.log('form', this.form.goodsContent)
          this.$message.success('校验成功')
        })
        .catch((err) => {
          console.log('err', err)
          console.log('err', err.errorFields)
          try {
            err.errorFields.forEach((item) => {
              console.log('001', item.name)
              // this.form.scrollToField(item.name) // 滚动到对应字段位置?
              if (
                item.name.join(',').indexOf('goodsName') === 0 ||
                item.name.join(',').indexOf('sellingPoint') === 0
              ) {
                this.activeKey = 1
                throw Error()
              }
              if (item.name.join(',').indexOf('goodsContent') === 0) {
                this.activeKey = 3
                this.$refs.form.validateFields(['goodsContent'])
                throw Error()
              }
            })
          } catch (e) {}

          // this.activeKey = 1
          this.$message.error('校验失败')
        })
    },
    // 批量设置-立刻设置
    batchClick() {
      // console.log(this.dataSource)
      if (this.goodsPriceValue !== null && this.goodsPriceValue !== '') {
        this.dataSource.map((item) => {
          item.goodsPrice = this.goodsPriceValue
        })
      }
      if (this.scribingPriceValue !== null && this.scribingPriceValue !== '') {
        this.dataSource.map((item) => {
          item.scribingPrice = this.scribingPriceValue
        })
      }
      if (this.amountValue !== null && this.amountValue !== '') {
        this.dataSource.map((item) => {
          item.amount = this.amountValue
        })
      }
      if (this.goodsWeightValue !== null && this.goodsWeightValue !== '') {
        this.dataSource.map((item) => {
          item.goodsWeight = this.goodsWeightValue
        })
      }
      if (this.skuValue !== null && this.skuValue !== '') {
        this.dataSource.map((item) => {
          item.sku = this.skuValue
        })
      }
    },
    // saveProcess() {
    //   const that = this
    //   const hide = that.$message.loading('提交中..', 0)
    //   that.$refs.form
    //     .validate()
    //     .then(() => {
    //       that.loading = true
    //       if (that.fileList.length > 0) {
    //         that.form.goodsMainPic = that.fileList[that.radioValue].url
    //         const urls = that.fileList.map((item) => {
    //           return item.url
    //         })
    //         if (urls.length > 0) {
    //           that.form.goodsPics = urls.join(',')
    //         }
    //       } else {
    //         that.form.goodsMainPic = ''
    //         that.form.goodsPics = ''
    //       }
    //       that.form.goodsContent = that.content
    //       pointsGoodsApi
    //         .save(that.form, that.isUpdate)
    //         .then((res) => {
    //           hide()
    //           that.loading = false
    //           if (res.code === 0) {
    //             Modal.confirm({
    //               title: '提示信息',
    //               content: '您已修改，是否返回上一页？',
    //               okText: '确认',
    //               cancelText: '取消',
    //               onOk() {
    //                 that.$message.success(res.msg)
    //                 that.form.goodsContent = res.data
    //                 that.$router.push({
    //                   path: './product'
    //                 })
    //               }
    //             })
    //           } else {
    //             that.$message.error(res.msg)
    //           }
    //         })
    //         .catch((e) => {
    //           that.loading = false
    //           that.$message.error(e.message)
    //         })
    //     })
    //     .catch(() => {})
    // },
    /* 获取编辑器纯文本内容 */
    showText() {
      Modal.info({
        maskClosable: true,
        content: this.$util.htmlToText(this.value)
      })
    },
    // beforeUploadFilevideo(file) {
    //   if (this.imgList.length === 1) {
    //     const newFileList = this.imgList.slice()
    //     newFileList.splice(-1, 1)
    //     // 只取前1个
    //     this.fileList = newFileList
    //   } else {
    //     this.fileList = [...this.fileList, file]
    //   }
    // },
    fileListChange(record, e) {
      console.log('上传', this.dataSource)
      console.log('上传record', record)
      console.log('1234', record.imgList)
      console.log('e', e.fileList)
      record.imgList = e.fileList
      console.log('record.imgList', record.imgList)
      // let imgListLength = this.imgList.length
      // for (let i = 0; i < imgListLength; i++) {
      //   if (this.dataSource[i][attrName] === attrVal) {
      //     this.dataSource.splice(i, 1)
      //     i = i - 1
      //     dataLength = dataLength - 1
      //   }
      // }
      // const { imgList } = this
      // // 限制只上传一个文件，再次上传时则替换(覆盖)以前的文件
      // if (imgList.length >= 1) {
      //   imgList.splice(0, 1)
      // }
    },
    // 删除图片
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.form.goodsImage = ''
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 上传图片
    uploadFile(record, file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'goodsImage') {
              record.imgList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    skuHandlePreview(file) {
      this.skuPreviewImage = file.url || file.preview
      this.skuPreviewVisible = true
    },
    handleCancel() {
      this.skuPreviewVisible = false
    },
    // queryArticle() {
    //   const query = this.$route.query
    //   if (query.goodsId > 0) {
    //     const that = this
    //     that.isUpdate = true
    //     const hide = that.$message.loading('请求中..', 0)
    //     pointsGoodsApi
    //       .getById(query.goodsId)
    //       .then((res) => {
    //         hide()
    //         if (res.code === 0) {
    //           that.form = Object.assign({}, that.data, res.data)
    //           that.content = that.form.goodsContent
    //           that.fileList = []

    //           if (that.form.goodsPics) {
    //             const goodsPicsUrl = that.form.goodsPics.split(',')
    //             goodsPicsUrl.map((item, index) => {
    //               console.log('edit-goodsPicsUrl', item)
    //               if (item === that.form.goodsMainPic) {
    //                 that.radioValue = index
    //               }
    //               that.fileList.push({
    //                 name: 'image.png',
    //                 status: 'done',
    //                 url: item,
    //                 uid: index
    //               })
    //             })
    //           }
    //         } else {
    //           that.$message.error(res.msg)
    //         }
    //       })
    //       .catch((e) => {
    //         hide()
    //         that.$message.error(e.message)
    //       })
    //   }
    // },
    // 获取商品名称List
    // querysortList() {
    //   pointsCategoryApi
    //     .all()
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.sortList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    // // 状态字典项
    // queryDataStates() {
    //   dictDataApi
    //     .querydictdata('shelfs')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.stateDict = res.data
    //         this.stateDict.map((item) => {
    //           item.dictDataCode = parseInt(item.dictDataCode)
    //         })
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    radioChange(e) {
      this.radioValue = e.target.value
      // console.log('单选', this.radioValue)
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 50px;
  height: 50px;
  margin-right: 0px;
  margin-bottom: 0px;
}

:deep(.ant-upload-list-picture-card .ant-upload-list-item) {
  width: 50px;
  height: 50px;
  margin: 0px;
  padding: 2px;
}
:deep(.ant-input-number) {
  width: 100%;
}

// :deep(.ant-upload-picture-card-wrapper) {
//   display: flex;
//   justify-content: center;
// }
:deep(.ant-upload-list-picture-card-container) {
  width: 50px;
  height: 50px;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 10px;
}

:deep(.ant-upload.ant-upload-select-picture-card > .ant-upload) {
  padding: 2px;
}

.span {
  color: grey;
  padding-left: 10px;
}

.big {
  font-size: 20px;
}

.radio {
  width: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}
.uploadStyle {
  display: flex;
  justify-content: center;
}

.goodsSpecs {
  margin: 10px 0 20px 0;

  .goodsSpecsHeader {
    background: #f4f5f9;
    padding: 10px;

    a {
      display: flex;
      align-items: center;
    }
  }

  .goodsSpecsValue {
    background: #fbfbfb;
    padding: 10px 20px;
    position: relative;

    a {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    .iconStyle {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
}

.addSpecsBtn {
  margin: 20px 0;
}
</style>
